<template>
  <div class="about">
    <div class="w-full py-36 text-center">
      <h1 class="text-6xl">phog</h1>
      <h3 class="text-3xl py-4">your photo catalog</h3>
    </div>

    <div class="flex xl:p-4 xl:flex-row flex-col items-center justify-center">
      <div
        class="
          w-full
          flex flex-col
          justify-center
          items-center
          py-48
          text-white
          bg-gradient-to-bl
          from-yellow-500
          to-red-500
          max-w-5xl
          lg:rounded-lg lg:shadow-lg lg:py-8
        "
      >
        <h1 class="text-8xl">0$<span class="text-6xl"> / mo</span></h1>
        <ul class="">
          <li class="pt-4 flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline pr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Yes It's Free For Now
          </li>
          <li class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline pr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Bring Your Own Storage
          </li>
          <li class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline pr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Unlimited Devices
          </li>
          <li class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline pr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            Always Up To Date
          </li>
        </ul>
        <button
          class="rounded-lg bg-white shadow-lg p-4 text-black text-xl mt-16"
          @click="signUp()"
        >
          Sign Up
        </button>
      </div>

      <div class="lg:grid lg:grid-cols-3 max-w-5xl">
        <div class="w-full lg:items-start md:flex p-8 md:justify-start md:items-center">
          <div class="m-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          </div>
          <div class="">
            <h2 class="text-2xl">Your Storage</h2>
            <p>
              No limits. No upgrades. We work directly with your existing
              photos. No write access required.
            </p>
          </div>
        </div>
        <div class="w-full lg:items-start md:flex p-8 md:justify-start md:items-center">
          <div class="m-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
              />
            </svg>
          </div>
          <div class="">
            <h2 class="text-2xl">Available Everywhere</h2>
            <p>
              Phones, Tablets, Laptops, Spaceships. Connect once, share
              anywhere.
            </p>
          </div>
        </div>
        <div class="w-full lg:items-start md:flex p-8 md:justify-start md:items-center">
          <div class="m-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
              />
            </svg>
          </div>
          <div class="">
            <h2 class="text-2xl">Always Up To Date</h2>
            <p>No paid upgrades, no license hassle.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  props: {},
  data: () => ({}),
})
export default class SignUpPage extends Vue {
  async signUp() {
    /* eslint-disable @typescript-eslint/camelcase */
    try {
      const result = await this.$auth.loginWithRedirect({screen_hint: 'signup'})
    } catch(e) {
      console.log("Signup Error", e)
    }
  }
}
</script>